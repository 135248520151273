import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as RootState from '..';
import * as AnalyticsActions from './analytics.actions';
import {
  ICampaignJsonDetailReport,
  ICampaignReport,
  MachineDetailedValues,
  MachinesMeasurementValue,
  MachinesOperatingValue,
  OperatingAnalysisList,
  PaginatedMachines,
} from '@app/shared/models/analytics.model';
import { MachinesService } from '@app/shared/services/machines.service';
import { IMachines } from '@app/shared/models/machines.model';
import { TelemetryService } from '@app/shared/services/telemetry.service';

@Injectable()
export class AnalyticsEffects {
  timeZoneWatcher: Subscription;

  measurementValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.MEASUREMENT_VALUES),
      mergeMap((data: { payload: { ids: string[]; timeZone: string }; type: string }) =>
        this.analyticsService.getMachinesMeasurementValues(data.payload.ids, data.payload.timeZone).pipe(
          take(1),
          map((measurementValues: MachinesMeasurementValue[]) => new AnalyticsActions.MeasurementValuesSuccess(measurementValues)),
          catchError((error) => of(new AnalyticsActions.MeasurementValuesFailure(error))),
          finalize(() => {
            this.store.dispatch(new AnalyticsActions.MeasurementValuesComplete());
          })
        )
      )
    )
  );

  operatingValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.OPERATING_VALUES),
      mergeMap((data: { payload: { ids: string[]; timeZone: string; widgetid?: string }; type: string }) =>
        this.analyticsService.getMachinesOperatingValues(data.payload.ids, data.payload.timeZone, data.payload.widgetid).pipe(
          take(1),
          map((operatingValues: MachinesOperatingValue[]) => new AnalyticsActions.OperatingValuesSuccess(operatingValues)),
          catchError((error) => of(new AnalyticsActions.OperatingValuesFailure(error))),
          finalize(() => {
            this.store.dispatch(new AnalyticsActions.OperatingValuesComplete());
          })
        )
      )
    )
  );

  machinesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.MACHINES_LIST),
      switchMap(() =>
        this.machinesService.listWithDataLogger().pipe(
          take(1),
          map((machinesList: IMachines[]) => new AnalyticsActions.MachinesListSuccess(machinesList)),
          catchError((error) => of(new AnalyticsActions.MachinesListFailure(error))),
          finalize(() => {
            this.store.dispatch(new AnalyticsActions.MachinesListComplete());
          })
        )
      )
    )
  );

  machine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.MACHINE),
      mergeMap((data: { payload: { uuid: string }; type: string }) =>
        this.machinesService.details(data.payload.uuid).pipe(
          take(1),
          map((machine: IMachines) => new AnalyticsActions.MachineSuccess(machine)),
          catchError((error) => of(new AnalyticsActions.MachineFailure(error))),
          finalize(() => {
            this.store.dispatch(new AnalyticsActions.MachineComplete());
          })
        )
      )
    )
  );

  operatingAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.OPERATING_ANALYSIS),
      mergeMap(
        (data: {
          payload: {
            uuid: string;
            timeZone: string;
            measuredFromLocalDate: string;
            measuredUntilLocalDate: string;
            calculateEngineOffDuration?: boolean;
          };
        }) =>
          this.telemetryService
            .getOperatingAnalysis(
              data.payload.uuid,
              data.payload.timeZone,
              data.payload.measuredFromLocalDate,
              data.payload.measuredUntilLocalDate,
              data.payload.calculateEngineOffDuration
            )
            .pipe(
              take(1),
              map((operatingAnalysis: OperatingAnalysisList) => new AnalyticsActions.OperatingAnalysisSuccess(operatingAnalysis)),
              catchError((error) => of(new AnalyticsActions.OperatingAnalysisFailure(error))),
              finalize(() => this.store.dispatch(new AnalyticsActions.OperatingAnalysisComplete()))
            )
      )
    )
  );

  machineDetailedValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.DETAILED_VALUES),
      mergeMap(
        (data: {
          payload: {
            uuid: string;
            timeZone: string;
            measuredFromLocalDate: string;
            measuredUntilLocalDate: string;
          };
        }) =>
          this.analyticsService
            .getMachineDetailedValues(
              data.payload.uuid,
              data.payload.timeZone,
              data.payload.measuredFromLocalDate,
              data.payload.measuredUntilLocalDate
            )
            .pipe(
              take(1),
              map((machineDetailedValues: MachineDetailedValues) => new AnalyticsActions.DetailedValuesSuccess(machineDetailedValues)),
              catchError((error) => of(new AnalyticsActions.DetailedValuesFailure(error))),
              finalize(() => this.store.dispatch(new AnalyticsActions.DetailedValuesComplete()))
            )
      )
    )
  );

  paginatedMachines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.PAGINATED_MACHINES),
      mergeMap((data: { payload: { page: number; size: number; characteristic: string; machineIds: string; archived: boolean } }) =>
        this.analyticsService
          .getPaginatedMachines(
            data.payload.page,
            data.payload.size,
            data.payload.characteristic,
            data.payload.machineIds,
            data.payload.archived
          )
          .pipe(
            take(1),
            map((paginatedMachines: PaginatedMachines) => new AnalyticsActions.PaginatedMachinesListSuccess(paginatedMachines)),
            catchError((error) => of(new AnalyticsActions.PaginatedMachinesListFailure(error))),
            finalize(() => this.store.dispatch(new AnalyticsActions.PaginatedMachinesListComplete()))
          )
      )
    )
  );

  getCampaignReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.GET_CAMPAIGN_REPORT),
      map((action: AnalyticsActions.GetCampaignReport) => action.campaignReportId),
      mergeMap((campaignReportId: string) =>
        this.analyticsService.getCampaignReport(campaignReportId).pipe(
          map((campaignReport: ICampaignReport) => new AnalyticsActions.GetCampaignReportSuccess(campaignReport)),
          catchError((error) => of(new AnalyticsActions.GetCampaignReportFailure(error))),
          finalize(() => {
            this.store.dispatch(new AnalyticsActions.GetCampaignReportComplete());
          })
        )
      )
    )
  );

  getJsonCampaignReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.GET_JSON_CAMPAIGN_REPORT),
      map((action: AnalyticsActions.GetJsonCampaignReport) => action.campaignReportId),
      mergeMap((campaignReportId: string) =>
        this.analyticsService.getCampaignReportJsonExport(campaignReportId).pipe(
          map(
            (campaignJsonDetailReports: ICampaignJsonDetailReport[]) =>
              new AnalyticsActions.GetJsonCampaignReportSuccess(campaignReportId, campaignJsonDetailReports)
          ),
          catchError((error) => of(new AnalyticsActions.GetJsonCampaignReportFailure(error))),
          finalize(() => {
            this.store.dispatch(new AnalyticsActions.GetJsonCampaignReportComplete());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private analyticsService: AnalyticsService,
    private machinesService: MachinesService,
    private store: Store<RootState.IState>, // tslint:disable-line:no-any
    private telemetryService: TelemetryService
  ) {}
}
