import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
  Contracts,
  IAlarms,
  IKpiWidgetData,
  ILicenseListRequest,
  IMachineTelemetryData,
  IMeasurements,
  MachineInfo,
} from '@app/shared/models/machine-overview.model';
import { MachinesService } from '@app/shared/services/machines.service';
import * as RootState from '@app/shared/state';
import * as MachineInfoActions from './machine-details.actions';

import {
  IAlarmsByMonth,
  IDeleteImageRequest,
  IEventNames,
  ILastMissedMaintenance,
  ILastPerformedMaintenance,
  ImachineComponentEditedItem,
  ImachineComponentItem,
  IMachineImage,
  IMachineMaintenance,
  IMachineMaintenancePayload,
  IMachinePatch,
  INextMaintenance,
  IPointDataList,
  ITelemetryEventRequest,
  LubricantsAdvisor,
  IOperatingInstructions,
  Category,
  ISoftwareComponent,
  IMachineConsents,
  IMachinePatchResponse,
} from '@app/shared/models/machines.model';
import { TelemetryService } from '@app/shared/services/telemetry.service';
import { NotesService } from '@app/shared/services/notes.service';
import { IMachinesNote, IMachinesNoteRequest } from '@app/shared/models/machines.notes.model';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { ToasterClass, ToasterPosition, ToasterType } from '@app/cde-toast/utils/toastr-options';
import { TranslateService } from '@ngx-translate/core';
import { MaintenanceService } from '@app/shared/services/maintenance.service';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import { ShopsService } from '@app/shared/services/shops.service';
import { RetrofitService } from '@app/shared/services/retrofit.service';
import { IMachineRetrofitsResponse, IRetrofit } from '@app/shared/models/retrofit.model';

@Injectable()
export class MachinesDetailsEffects {
  alertOptions = {
    ...TOASTR_ALERT_OPTIONS,
    timeOut: 3000,
  };

  details$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.DETAILS),
      map((action: MachineInfoActions.Details) => action.payload),
      tap(() => this.store.dispatch(new MachineInfoActions.ClearEntities())),
      switchMap((payload: { id: string; timeZone: string }) =>
        this.machinesService.info(payload['id'], payload['timeZone']).pipe(
          take(1),
          map((info: MachineInfo) => new MachineInfoActions.DetailsSuccess(info)),
          catchError((error) => of(new MachineInfoActions.DetailsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.DetailsComplete());
          })
        )
      )
    )
  );

  track$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.TRACK),
      map((action: MachineInfoActions.Track) => action.payload),
      switchMap((payload) =>
        this.telemetryService.getTrack(payload.id, payload.timeZone, payload.measuredAtLocalDate, payload.category).pipe(
          map((track: string) => new MachineInfoActions.TrackSuccess(track)),
          catchError((error) => of(new MachineInfoActions.TrackFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.TrackComplete());
          })
        )
      )
    )
  );

  telemetryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.TELEMETRY_ALARM_DATA),
      map((action: MachineInfoActions.TelemetryDaysData) => action.payload),
      switchMap((payload) =>
        this.telemetryService.getTelemtryDays(payload.id, payload.timeZone, payload.localMonth, payload.localYear, payload.category).pipe(
          map((dates: string[]) => new MachineInfoActions.TelemetryDaysDataSuccess(dates)),
          catchError((error) => of(new MachineInfoActions.TelemetryDaysDataFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.TelemetryDaysDataComplete());
          })
        )
      )
    )
  );

  trackCoordinates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.TRACK_COORDINATES),
      map((action: MachineInfoActions.TrackCoordinates) => action.payload),
      switchMap((payload) =>
        this.telemetryService
          .getTrackCoordinates(
            payload.id,
            payload.timeZone,
            payload.measuredFromLocalDate,
            payload.measuredUntilLocalDate,
            payload.category
          )
          .pipe(
            map(
              (track: string) => new MachineInfoActions.TrackCoordinatesSuccess(track) // TODO
            ),
            catchError((error) => of(new MachineInfoActions.TrackCoordinatesFailure(error))),
            finalize(() => {
              this.store.dispatch(new MachineInfoActions.TrackComplete());
            })
          )
      )
    )
  );

  trackAlarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.TRACK_ALARM_DATE),
      map((action: MachineInfoActions.TrackAlarmDate) => action.payload),
      switchMap((payload) =>
        this.machinesService
          .getAlarmsByRange(payload.id, payload.timeZone, payload.measuredFromLocalDate, payload.measuredUntilLocalDate, payload.language)
          .pipe(
            map((track: IAlarms) => new MachineInfoActions.TrackAlarmDateSuccess(track)),
            catchError((error) => of(new MachineInfoActions.TrackAlarmDateFailure(error))),
            finalize(() => {
              this.store.dispatch(new MachineInfoActions.TrackAlarmDateComplete());
            })
          )
      )
    )
  );

  alarmDays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.ALARMS_DAYS),
      map((action: MachineInfoActions.AlarmsDaysList) => action.payload),
      switchMap((payload: any) =>
        this.machinesService.getAlarmsByMonth(payload['id'], payload['timeZone'], payload['month'], payload['year']).pipe(
          take(1),
          map((alarmDays: IAlarmsByMonth) => new MachineInfoActions.AlarmsDaysSuccess(alarmDays)),
          catchError((error) => of(new MachineInfoActions.AlarmsDaysFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.AlarmsDaysComplete());
          })
        )
      )
    )
  );

  contracts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.CONTRACTS),
      map((action: MachineInfoActions.ContractsList) => action),
      switchMap((payload: any) =>
        this.machinesService.getContracts(payload.uuid, payload.language).pipe(
          take(1),
          map((info: Contracts) => new MachineInfoActions.ContractsSuccess(info)),
          catchError((error) => of(new MachineInfoActions.ContractsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.ContractsComplete());
          })
        )
      )
    )
  );

  licenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.LICENSES),
      map((action: MachineInfoActions.LicensesList) => action.payload),
      switchMap((payload: ILicenseListRequest) =>
        this.machinesService.getLicenses(payload.uuid, payload.language, payload.machineLicense).pipe(
          take(1),
          map((licenses) => new MachineInfoActions.LicensesSuccess(licenses)),
          catchError((error) => of(new MachineInfoActions.LicensesFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.LicensesComplete());
          })
        )
      )
    )
  );

  activateLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.ACTIVATE_LICENSES),
      map((action: MachineInfoActions.ActivateLicense) => action.payload),
      mergeMap((payload: any) =>
        this.machinesService.activateLicense(payload.machineId, payload.licenseId, payload.language, payload.machineLicense).pipe(
          take(1),
          map((license) => {
            this.alertService.callSnackbarSuccess(
              this.translate.instant('license.farm.license_activation.success'),
              '',
              ToasterType.snackbarSuccess,
              {
                positionClass: ToasterPosition.bottomCenter,
              }
            );

            return new MachineInfoActions.ActivateLicenseSuccess(license);
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('machine_details.license.activation_failed'),
              '',
              'alert-error-icon',
              this.alertOptions
            );
            return of(new MachineInfoActions.ActivateLicenseFailure({ ...error, data: { ...error.data, licenseId: payload.licenseId } }));
          }),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.ActivateLicenseComplete());
          })
        )
      )
    )
  );

  extendLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.EXTEND_LICENSES),
      map((action: MachineInfoActions.ExtendLicense) => action.payload),
      switchMap((payload: { organizationId: string; dealerId: string }) =>
        this.shopsService.getOrganisationDealer(payload.organizationId, payload.dealerId).pipe(
          map((license) => new MachineInfoActions.ExtendLicenseSuccess(license)),
          catchError((error) => of(new MachineInfoActions.ExtendLicenseFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.ExtendLicenseComplete());
          })
        )
      )
    )
  );

  lubricants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.LUBRICANTS),
      map((action: MachineInfoActions.LubricantsList) => action.payload),
      switchMap((payload) =>
        this.machinesService.getLubricants(payload).pipe(
          take(1),
          map((lubricants) => {
            const lubricantsList: LubricantsAdvisor[] = Object.values(lubricants);
            return new MachineInfoActions.LubricantsSuccess(lubricantsList);
          }),
          catchError((error) => of(new MachineInfoActions.LubricantsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.LubricantsComplete());
          })
        )
      )
    )
  );

  instructions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.INSTRUCTIONS),
      map((action: MachineInfoActions.InstructionsList) => action.payload),
      switchMap((payload) =>
        this.machinesService.getInstructions(payload).pipe(
          take(1),
          map((instructions) => {
            const instructionsList: IOperatingInstructions[] = Object.values(instructions);
            return new MachineInfoActions.InstructionsSuccess(instructionsList);
          }),
          catchError((error) => of(new MachineInfoActions.InstructionsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.InstructionsComplete());
          })
        )
      )
    )
  );

  maintenance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.MAINTENANCE),
      map((action: MachineInfoActions.Maintenance) => action.payload),
      switchMap((payload: IMachineMaintenancePayload) =>
        this.maintenanceService.getMaintenence(payload['id'], payload['operatingHours']).pipe(
          take(1),
          map((data: IMachineMaintenance) => {
            const maintenanceData: IMachineMaintenance = {
              ...data,
            };
            return maintenanceData;
          }),
          map((maintenanceData) => new MachineInfoActions.MaintenanceSuccess(maintenanceData)),
          catchError((error) => of(new MachineInfoActions.MaintenanceFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.MaintenanceComplete());
          })
        )
      )
    )
  );

  maintenanceNext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.MAINTENANCE_NEXT),
      map((action: MachineInfoActions.MaintenanceNext) => action.payload),
      switchMap((payload: IMachineMaintenancePayload) =>
        this.maintenanceService.getNextMaintenence(payload['id'], payload['operatingHours']).pipe(
          take(1),
          map((data: INextMaintenance) => {
            const maintenance = { ...data };
            const maintenanceData: INextMaintenance = {
              ...maintenance,
            };
            return maintenanceData;
          }),
          map((maintenanceData) => new MachineInfoActions.MaintenanceNextSuccess(maintenanceData)),
          catchError((error) => of(new MachineInfoActions.MaintenanceNextFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.MaintenanceNextComplete());
          })
        )
      )
    )
  );

  maintenanceLastMissed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.MAINTENANCE_LAST_MISSED),
      map((action: MachineInfoActions.MaintenanceLastMissed) => action.payload),
      switchMap((payload: { id: string }) =>
        this.maintenanceService.getLastMissedMaintenence(payload['id']).pipe(
          take(1),
          map((data: ILastMissedMaintenance) => {
            const maintenance = { ...data };
            const maintenanceData: ILastMissedMaintenance = {
              ...maintenance,
            };
            return maintenanceData;
          }),
          map((maintenanceData) => new MachineInfoActions.MaintenanceLastMissedSuccess(maintenanceData)),
          catchError((error) => of(new MachineInfoActions.MaintenanceLastMissedFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.MaintenanceLastMissedComplete());
          })
        )
      )
    )
  );

  maintenanceLastPerformed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.MAINTENANCE_LAST_PERFORMED),
      map((action: MachineInfoActions.MaintenanceLastPerformed) => action.payload),
      switchMap((payload: { id: string }) =>
        this.maintenanceService.getLastPerformedMaintenence(payload['id']).pipe(
          take(1),
          map((data: ILastPerformedMaintenance) => {
            const maintenance = { ...data };
            const maintenanceData: ILastPerformedMaintenance = {
              ...maintenance,
            };
            return maintenanceData;
          }),
          map((maintenanceData) => new MachineInfoActions.MaintenanceLastPerformedSuccess(maintenanceData)),
          catchError((error) => of(new MachineInfoActions.MaintenanceLastPerformedFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.MaintenanceLastPerformedComplete());
          })
        )
      )
    )
  );

  eventsTelemetry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.EVENT_TELEMETRY),
      withLatestFrom(this.store),
      map(
        ([action, store]: [MachineInfoActions.TelemetryEvents, RootState.IState]) =>
          [action.payload, store.machineDetails.eventTelemetryPending] as [ITelemetryEventRequest, boolean]
      ),
      mergeMap(([payload, pending]) =>
        this.telemetryService
          .getTelemetryEvent(
            payload.id,
            payload.eventName,
            payload.timeZone,
            payload.measuredFromLocalDate,
            payload.measuredUntilLocalDate,
            pending
          )
          .pipe(
            map(
              (data: any) =>
                new MachineInfoActions.TelemetryEventsSuccess({
                  data: data,
                  eventName: payload.eventName,
                })
            ),
            catchError((error) => of(new MachineInfoActions.TelemetryEventsFailure(error))),
            finalize(() => {
              this.store.dispatch(new MachineInfoActions.TelemetryEventsComplete());
            })
          )
      )
    )
  );

  eventNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.EVENT_NAME),
      map((action: MachineInfoActions.EventName) => action.payload),
      switchMap((payload: string) =>
        this.machinesService.getEventNames(payload).pipe(
          map((names: IEventNames) => new MachineInfoActions.EventNameSuccess(names)),
          catchError((error) => of(new MachineInfoActions.EventNameFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.EventNameComplete());
          })
        )
      )
    )
  );

  telemetryPointdata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.TELEMETRY_POINTDATA),
      map((action: MachineInfoActions.TelemetryPointdata) => action.payload),
      mergeMap((payload) =>
        this.machinesService
          .getPointData(
            payload.id,
            payload.measurementName,
            payload.timeZone,
            payload.measuredFromLocalDate,
            payload.measuredUntilLocalDate,
            payload.category
          )
          .pipe(
            map((pointdataList: IPointDataList) => {
              const ret: IPointDataList = {
                content: pointdataList.content,
                legend: {
                  items: pointdataList.legend.items,
                  name: this.translate.instant('filter.pointData.' + pointdataList.metadata.key),
                },
                metadata: pointdataList.metadata,
              };

              return new MachineInfoActions.TelemetryPointdataSuccess({
                pointDataList: ret,
              });
            }),
            catchError((error) => of(new MachineInfoActions.TelemetryPointdataFailure(error))),
            finalize(() => {
              this.store.dispatch(new MachineInfoActions.TelemetryPointdataComplete());
            })
          )
      )
    )
  );

  imageFileAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.IMAGEADD),
      mergeMap((data: { payload: IMachineImage }) =>
        this.machinesService.postImage(data.payload).pipe(
          take(1),
          map((imageInfo: IMachineImage) => new MachineInfoActions.ImageAddSuccess(imageInfo)),
          catchError((error) => of(new MachineInfoActions.ImageAddFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.ImageAddComplete());
          })
        )
      )
    )
  );

  imageFileDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.IMAGEDELETE),
      map((action: MachineInfoActions.ImageDelete) => action.payload),
      switchMap((payload: IDeleteImageRequest) =>
        this.machinesService.deleteImage(payload).pipe(
          map((imageInfo: string | undefined) => {
            this.alertService.callSnackbarSuccess(
              this.translate.instant('machine_details.edit.image_delete_success'),
              '',
              ToasterType.snackbarSuccess,
              {}
            );
            return new MachineInfoActions.ImageDeleteSuccess(imageInfo);
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('machine_details.edit.could_not_remove_image'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new MachineInfoActions.ImageAddFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.ImageDeleteComplete());
          })
        )
      )
    )
  );

  machinePatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.MACHINEPATCH),
      map((action: MachineInfoActions.MachinePatch) => action.payload),
      switchMap((payload: IMachinePatch) =>
        this.machinesService.patchMachine(payload).pipe(
          map((response: IMachinePatchResponse) => {
            this.alertService.callSnackbarSuccess(
              this.translate.instant('machine_details.edit.success'),
              '',
              ToasterType.snackbarSuccess,
              {}
            );
            return new MachineInfoActions.MachinePatchSuccess(response);
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('machine_details.edit.error'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new MachineInfoActions.MachinePatchFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.MachinePatchComplete());
          })
        )
      )
    )
  );

  pointDataNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.POINTDATANAMES),
      map((action: MachineInfoActions.PointDataNames) => action.payload),
      switchMap((payload) =>
        this.machinesService.getPointDataNames(payload.id, payload.category).pipe(
          take(1),
          map((pointDataNames) => new MachineInfoActions.PointDataNamesSuccess(pointDataNames)),
          catchError((error) => of(new MachineInfoActions.PointDataNamesFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.PointDataNamesComplete());
          })
        )
      )
    )
  );

  notes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.NOTES),
      map((action: MachineInfoActions.NotesList) => action.payload),
      switchMap((payload: { type: string; machineId: string; sortBy: string }) =>
        this.notesService.getAllNotes(payload.type, payload.machineId, payload.sortBy).pipe(
          take(1),
          map((info: IMachinesNote) => {
            const notesList: IMachinesNote[] = Object.values(info);
            return new MachineInfoActions.NotesSuccess(notesList);
          }),
          catchError((error) => of(new MachineInfoActions.NotesFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.NotesComplete());
          })
        )
      )
    )
  );

  addNote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MachineInfoActions.ADD_NOTE),
        map((action: MachineInfoActions.AddNote) => action.payload),
        switchMap((payload: IMachinesNoteRequest) =>
          this.notesService.addNewNote(payload).pipe(
            take(1),
            map((result: IMachinesNote) => {
              if (result) {
                const newNote = { ...result };
                this.store.dispatch(new MachineInfoActions.AddNoteSuccess(newNote));
                this.alertService.showSuccess(this.translate.instant('notes.note_add_success'), '', 'snackbar-success', {
                  positionClass: ToasterPosition.bottomLeft,
                  type: ToasterType.snackbarSuccess,
                  toastClass: ToasterClass.snackbarSuccess,
                  closeButton: true,
                });
              }
            }),
            catchError((error) => {
              this.alertService.showError(
                this.translate.instant('notes.note_add_failure'),
                this.translate.instant('alert_message.try_again'),
                'alert-error-icon',
                this.alertOptions
              );
              return of(new MachineInfoActions.AddNoteFailure(error));
            }),
            finalize(() => {
              this.store.dispatch(new MachineInfoActions.AddNoteComplete());
            })
          )
        )
      ),
    { dispatch: false }
  );

  updateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.UPDATE_NOTE),
      map((action: MachineInfoActions.UpdateNote) => action.payload),
      switchMap((payload: { data: IMachinesNoteRequest; noteId: string }) =>
        this.notesService.updateNoteById(payload.data, payload.noteId).pipe(
          take(1),
          map((note: IMachinesNote) => {
            this.alertService.callSnackbarSuccess(this.translate.instant('notes.note_update_success'), '', ToasterType.snackbarSuccess, {});
            return new MachineInfoActions.UpdateNoteSuccess(note);
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('configuration.note_update_failure'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );

            return of(new MachineInfoActions.UpdateNoteFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.UpdateNoteComplete());
          })
        )
      )
    )
  );

  deleteNote$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(MachineInfoActions.DELETE_NOTE),
        map((action: MachineInfoActions.DeleteNote) => action.payload),
        switchMap((payload: { noteId: string }) =>
          this.notesService.deleteNoteById(payload.noteId).pipe(
            take(1),
            map(() => {
              this.alertService.callSnackbarSuccess(
                this.translate.instant('notes.note_delete_success'),
                '',
                ToasterType.snackbarSuccess,
                {}
              );
              return new MachineInfoActions.DeleteNoteSuccess({
                noteId: payload.noteId,
              });
            }),
            catchError((error) => {
              this.alertService.showError(
                this.translate.instant('notes.note_delete_failure'),
                this.translate.instant('alert_message.try_again'),
                'alert-error-icon',
                this.alertOptions
              );
              return of(new MachineInfoActions.DeleteNoteFailure(error));
            }),
            finalize(() => {
              this.store.dispatch(new MachineInfoActions.DeleteNoteComplete());
            })
          )
        )
      )
  );

  components$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.COMPONENTS),
      map((action: MachineInfoActions.ComponentsList) => action.payload),
      switchMap((payload: { machineId: string; language: string }) =>
        this.machinesService.getSoftwareComponents(payload.machineId, payload.language).pipe(
          take(1),
          map((info: ISoftwareComponent) => {
            const componentsEdited: ImachineComponentEditedItem[] = info.softwareComponents.map((item: ImachineComponentItem) => ({
              components: item.componentText,
              lastUpdate: item.backupAtLocalDate,
              version: item.version,
            }));

            return new MachineInfoActions.ComponentsSuccess(componentsEdited);
          }),
          catchError((error) => of(new MachineInfoActions.ComponentsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.ComponentsComplete());
          })
        )
      )
    )
  );

  getMachineUISections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.GET_MACHINE_UI_SECTIONS),
      map((action: MachineInfoActions.GetMachineUISections) => action.machineId),
      switchMap((machineId: string) =>
        this.machinesService.getMachineUISections(machineId).pipe(
          map((res) => new MachineInfoActions.GetMachineUISectionsSuccess(res)),
          catchError((error) => of(new MachineInfoActions.GetMachineUISectionsFailure(error))),
          finalize(() => this.store.dispatch(new MachineInfoActions.GetMachineUISectionsComplete()))
        )
      )
    )
  );

  machineTelemetryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.MACHINE_TELEMETRY_DATA),
      switchMap((payload: { uuid: string; timeZone: string; category?: Category }) => {
        const getTelemetryDataCall =
          payload.category === Category.IMPLEMENT
            ? this.machinesService.getImplementTelemetryData(payload.uuid)
            : this.machinesService.getMachineTelemetryData(payload.uuid, payload.timeZone);
        return getTelemetryDataCall.pipe(
          take(1),
          map((res: IMachineTelemetryData) => new MachineInfoActions.MachineTelemetryDataSuccess(res)),
          catchError((error) => of(new MachineInfoActions.MachineTelemetryDataFailure(error))),
          finalize(() => this.store.dispatch(new MachineInfoActions.MachineTelemetryDataComplete()))
        );
      })
    )
  );

  machineMapPopupTelemetryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.MACHINE_MAP_POPUP_TELEMETRY_DATA),
      switchMap((payload: { uuid: string; timeZone: string }) =>
        this.machinesService.getMachineMapPopupTelemetryData(payload.uuid, payload.timeZone).pipe(
          take(1),
          map((res: IMeasurements[]) => new MachineInfoActions.MachineMapPopupTelemetryDataSuccess(res)),
          catchError((error) => of(new MachineInfoActions.MachineMapPopupTelemetryDataFailure(error))),
          finalize(() => this.store.dispatch(new MachineInfoActions.MachineMapPopupTelemetryDataComplete()))
        )
      )
    )
  );

  machineKpiWidgetyData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.MACHINE_KPI_WIDGET_DATA),
      mergeMap((payload: { uuid: string; timeZone: string }) =>
        this.machinesService.getMachineKpiWigetData(payload.uuid, payload.timeZone).pipe(
          map((res: IKpiWidgetData) => new MachineInfoActions.MachineKpiWidgetDataSuccess(res)),
          catchError((error) => of(new MachineInfoActions.MachineKpiWidgetDataFailure(error))),
          finalize(() => this.store.dispatch(new MachineInfoActions.MachineKpiWidgetDataComplete()))
        )
      )
    )
  );

  getMachineRetrofits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.GET_MACHINE_RETROFITS),
      mergeMap((action: MachineInfoActions.GetMachineRetrofits) =>
        this.retrofitService.getRetrofits(action.machineId, action.language, action.timeZone).pipe(
          map((response: IMachineRetrofitsResponse) => new MachineInfoActions.GetMachineRetrofitsSuccess(action.machineId, response)),
          catchError((error) => {
            if (error.failCode !== 0 && error.failCode < 500) {
              this.alertService.showError(
                this.translate.instant('configuration.failed_to_load_config'),
                this.translate.instant('alert_message.network_error'),
                'alert-error-icon',
                this.alertOptions
              );
            }
            return of(new MachineInfoActions.GetMachineRetrofitsFailure(error));
          }),
          finalize(() => this.store.dispatch(new MachineInfoActions.GetMachineRetrofitsComplete()))
        )
      )
    )
  );

  createMachineRetrofit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.CREATE_MACHINE_RETROFIT),
      mergeMap((action: MachineInfoActions.CreateMachineRetrofit) =>
        this.retrofitService.createRetrofit(action.machineId, action.name, action.code, action.language, action.timeZone).pipe(
          map((response: IRetrofit) => new MachineInfoActions.CreateMachineRetrofitSuccess(action.machineId, response)),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('configuration.could_not_add_config'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );

            return of(new MachineInfoActions.CreateMachineRetrofitFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.CreateMachineRetrofitComplete());
          })
        )
      )
    )
  );

  deleteMachineRetrofit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.DELETE_MACHINE_RETROFIT),
      mergeMap((action: MachineInfoActions.DeleteMachineRetrofit) =>
        this.retrofitService.deleteRetrofit(action.machineId, action.machineRetrofitId).pipe(
          map(() => new MachineInfoActions.DeleteMachineRetrofitSuccess(action.machineId, action.machineRetrofitId)),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('configuration.could_not_delete_config'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );

            return of(new MachineInfoActions.DeleteMachineRetrofitFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new MachineInfoActions.DeleteMachineRetrofitComplete());
          })
        )
      )
    )
  );

  patchMachineRetrofit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.PATCH_MACHINE_RETROFIT),
      mergeMap((action: MachineInfoActions.PatchMachineRetrofit) =>
        this.retrofitService
          .patchRetrofit(action.machineId, action.machineRetrofitId, action.language, action.timeZone, action.request)
          .pipe(
            map((response) => new MachineInfoActions.PatchMachineRetrofitSuccess(action.machineId, action.machineRetrofitId, response)),
            catchError((error) => {
              this.alertService.showError(
                this.translate.instant('configuration.could_not_update_config'),
                this.translate.instant('alert_message.try_again'),
                'alert-error-icon',
                this.alertOptions
              );

              return of(new MachineInfoActions.PatchMachineRetrofitFailure(error));
            }),
            finalize(() => {
              this.store.dispatch(new MachineInfoActions.PatchMachineRetrofitComplete());
            })
          )
      )
    )
  );

  getMachineConsents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.GET_MACHINE_CONSENTS),
      mergeMap((action: MachineInfoActions.GetMachineConsents) =>
        this.machinesService.getMachineConsents(action.machineId).pipe(
          map((response: IMachineConsents) => new MachineInfoActions.GetMachineConsentsSuccess(response)),
          catchError((error) => of(new MachineInfoActions.GetMachineConsentsFailure(error))),
          finalize(() => this.store.dispatch(new MachineInfoActions.GetMachineConsentsComplete()))
        )
      )
    )
  );

  patchMachineConsents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineInfoActions.PATCH_MACHINE_CONSENTS),
      mergeMap((action: MachineInfoActions.PatchMachineConsents) =>
        this.machinesService.patchMachineConsents(action.machineId, action.request).pipe(
          map((response: IMachineConsents) => new MachineInfoActions.PatchMachineConsentsSuccess(response)),
          catchError((error) => of(new MachineInfoActions.PatchMachineConsentsFailure(error))),
          finalize(() => this.store.dispatch(new MachineInfoActions.PatchMachineConsentsComplete()))
        )
      )
    )
  );

  handlePatchMachineConsentsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MachineInfoActions.PATCH_MACHINE_CONSENTS_SUCCESS),
        tap((action: MachineInfoActions.PatchMachineConsentsSuccess) =>
          this.router.navigate(['machines', 'details', action.machineConsents.id])
        ),
        tap((action: MachineInfoActions.PatchMachineConsentsSuccess) => {
          this.alertService.callSnackbarSuccess(
            this.translate.instant('machine_details.maco.update_success'),
            '',
            ToasterType.snackbarSuccess,
            {
              positionClass: ToasterPosition.bottomCenter,
            }
          );
        })
      ),
    { dispatch: false }
  );

  showPatchMachineConsentsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MachineInfoActions.PATCH_MACHINE_CONSENTS_FAILURE),
        tap((action: MachineInfoActions.PatchMachineConsents) => {
          this.alertService.showError(
            this.translate.instant('machine_details.maco.update_error'),
            this.translate.instant('alert_message.try_again'),
            'alert-error-icon',
            this.alertOptions
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private machinesService: MachinesService,
    private maintenanceService: MaintenanceService,
    private notesService: NotesService,
    private shopsService: ShopsService,
    private store: Store<RootState.IState>, // tslint:disable-line:no-any
    private telemetryService: TelemetryService,
    private translate: TranslateService,
    private retrofitService: RetrofitService,
    private router: Router
  ) {}
}
